@import "../../assets/common.scss";

[data-test] {
    color: $light;
    background: $dark;
    font-size: 2rem;

    button {
        color: $accentColor;
        line-height: normal;
        letter-spacing: normal;
        padding: 5px;
        border: 2px solid $accentColor;
        border-radius: 10px;
      
        &:hover {
          color: $primaryColor;
          border-color: $primaryColor;
        }

        &:active {
            background: transparent;
        }
      }
}

[data-test="greetings-nav-bar"] {
    padding-bottom: 10px;
    border-bottom: 5px solid $primaryColor;
}